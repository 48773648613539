import React from 'react';
import ProductsList from '../components/common_view_components/ProductsList';
import {StaticQuery, graphql} from "gatsby";
import _ from 'lodash';

const query = graphql`
   query popularProductsQuery {
      sectionTexts: markdownRemark(frontmatter: { path: { eq: "/product" } }) {
           frontmatter{
              most_popular_products
              most_popular_product{
                popular_product_link_name
                section_heading
                subheader
                text1
                text2
                price_label
                group_label            
              }   
           }
      }
            
      productsList: allMarkdownRemark(filter:{frontmatter:{path:{eq:"/product/"}}}) {
        edges {
          node {    
            frontmatter {
              product{
                product_link_name
                product_price
                number_of_owners
                product_name
                currency
                price_per_person_label
                product_images 
                description_intro                
                description 
                tech_details 
                type_of_group
              }
            }
          }
        }
     }  
   }
`

const getRndInteger=(min, max)=>{
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}

const getRandomProductsFromList=(products_list, number_of_products)=>{
    if(number_of_products > products_list.length){
        return products_list;
    }
    let selected_products = [];
    let i = 0;
    let products_number = products_list.length - 1;
    while(i <= number_of_products){
        let rand_number = getRndInteger(0, products_number);
        let product = products_list[rand_number];
        if(selected_products.indexOf(product) === -1){
            selected_products.push(product);
            i++;
        }
    }
    return selected_products;
};


const PopularProducts = ()=>(
    <StaticQuery
        query={query}
        render={data=>{

        const products_list = data.productsList.edges.map((product_node)=>(product_node.node.frontmatter.product));
        const most_popular_product = data.sectionTexts.frontmatter.most_popular_product;
        const popular_product_index = _.findIndex(products_list, { 'product_link_name': most_popular_product.popular_product_link_name});        
        most_popular_product.product = products_list[popular_product_index];
        products_list.splice(popular_product_index, 1);        
        const products = getRandomProductsFromList(products_list, 4);
            
        const section_texts = data.sectionTexts.frontmatter.most_popular_products;
        return <ProductsList products_list={products} section_heading={section_texts} most_popular_product={most_popular_product} />
      }}
    />
);

export default PopularProducts;