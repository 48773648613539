import React from 'react';
import {graphql} from 'gatsby'
import {HTMLContent} from '../components/Content'

import Layout from '../components/Layout';
import FeatureComponent from '../components/common_view_components/FeatureComponent';
import PopularProducts from '../components/PopularProducts';

const AboutPageTemplate = ({data})=> {
    const {header_section, content_section} = data.markdownRemark.frontmatter;

    const ParagraphSection = ({paragraph})=>(
        <div>
          <h3>{paragraph.header}</h3>
          <HTMLContent className="subtitle-paragraph" content={paragraph.text}></HTMLContent>
        </div>
    )


    return (
        <Layout>
             <section className="main-section product-details-page about">
              <div className="container tab-container no-margin-top no-border">
                <div className="tab-content flexbox-space-between flex-wrap align-top">
                  <div className="section-copy">
                    <div className="section-heading">{header_section.header}</div>
                    <p className="subtitle-paragraph">{header_section.subheader}</p>
                    {
                        header_section.paragraphs.map((paragraph, index)=>(
                            <ParagraphSection paragraph={paragraph} key={index} />
                        ))
                    }
                  </div>

                  <div className="section-feature-list flexbox-space-between align-top no-margin">
                    {
                        header_section.features.map((feature, index)=>(
                            <FeatureComponent key={index} feature={feature}/>
                        ))
                    }
                  </div>
                </div>

                <div className="tab-content">
                  <img src={content_section.content_image} alt="Image" />
                  <div className="section-copy full-width">
                     {
                        content_section.paragraphs.map((paragraph, index)=>(
                            <ParagraphSection paragraph={paragraph} key={index} />
                        ))
                     }
                  </div>
                </div>
              </div>
            </section>
        
            <PopularProducts   />
        </Layout>
    );
};

export default AboutPageTemplate;

export const AboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header_section{
          header
          subheader
          paragraphs{
            text
          }
          features{
            img
            title
            details
          }
        }
        content_section{
          content_image
          header
          paragraphs{
            header
            text
          }
        }
      }
    }
  }
`